<template>
	<div
		v-if="article"
		class="max-w-6xl mx-auto"
	>
		<div class="flex flex-wrap max-w-3xl mx-auto lg:flex-nowrap">
			<div class="w-full xl:pt-2 blog-content">
				<div class="aspect-[16/9] w-full">
					<WwImage
						:src="articleImage"
						:alt="article.title"
						:width="710"
						:height="400"
						:image-path="BLOG_IMAGES"
						:lazy="false"
						fetch-priority="high"
					/>
				</div>
				<h1
					class="px-3 xl:px-0"
					v-html="article.title"
				/>
				<div class="relative flex items-center justify-between h-16 px-3 py-2 xl:px-0">
					<p class="text-gray-500">
						Published on {{ articleDate }}
					</p>
					<SocialShare>
						<template #default="{ share }">
							<button
								class="my-auto text-sm font-bold text-green-500 uppercase hover:text-purple-500"
								@click="share"
							>
								Share Article
							</button>
						</template>
					</SocialShare>
				</div>
				<div
					v-if="article.content"
					class="w-full max-w-4xl px-3 mx-auto prose xl:px-0"
					v-html="firstParagraph"
				/>
				<div class="flex flex-wrap justify-center px-3 mx-auto">
					<WwAdvertisement
						:zone="11"
						class="my-4"
					/>
				</div>
				<div
					v-if="article.content"
					class="w-full max-w-4xl px-3 mx-auto prose xl:px-0"
					v-html="articleContentSansFirstParagraph"
				/>
				<GleamEmbed
					v-if="hasGleamEmbed"
					class="flex justify-center w-full pt-8"
					:embed-html="gleamEmbedHtml"
				/>
				<SocialShare class="px-3 xl:px-0">
					<template #default="{ share }">
						<button
							class="text-sm font-bold text-green-500 uppercase hover:text-purple-500"
							@click="share"
						>
							Share Article
						</button>
					</template>
				</SocialShare>
				<AuthorCard
					v-if="hasAuthorInfo"
					:author="article.author"
					class="my-8"
				/>
				<WwLogo
					v-else
					:width="200"
					:height="200"
					full
					class="px-3 py-8 xl:px-0"
				/>
				<CityCarouselRow
					v-if="itemCount"
					class="w-full"
					:items="businesses"
					:list-type="BUSINESSES_LIST"
					:item-count="itemCount"
					heading="NEARBY BUSINESSES"
					:url="cityUrl + '/marijuana-dispensaries'"
				/>
				<CityCarouselRow
					v-if="hasRelatedArticles"
					class="w-full pb-8 mb-2"
					:items="relatedArticles"
					:list-type="BLOGS_LIST"
					:item-count="10"
					heading="RELATED ARTICLES"
					:url="`/blog/${articleCategoryUrl}`"
					bg-color="bg-white"
					fade-color-from="from-white"
					:sub-heading="subHeading"
					hide-border
				/>
			</div>
		</div>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import { createApolloClient } from '@/api/apollo/client.js'
import WwImage from '@/components/multiUse/WwImage.vue'
import { BLOG_IMAGES } from '@/constants/images/image-folder-paths.js'
import { CONTENT_SERVER } from '@/constants/index.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import { BLOGS_LIST, BUSINESSES_LIST } from '@/constants/search/listTypes.js'
import { GetBlogCategoriesList, GetNearbyBusinesses, GetRelatedArticles } from '@/gql/blog/queries/components.gql'
import { GetBlogArticlePageData } from '@/gql/blog/queries/pages.gql'
import buildArticleCard from '@/utils/builders/buildArticleCard.js'
import { componentLevelGQLErrors, logError, pageLevelGQLErrors } from '@/utils/error-handling.js'
import { formatUnixDate } from '@/utils/formatUnixTime.js'

const apolloClient = createApolloClient()

export default {
	components: {
		AuthorCard: () => import('@/components/blog/AuthorCard.vue'),
		CityCarouselRow: () => import('@/views/city/components/carousel-rows/CityCarouselRow.vue'),
		SocialShare: () => import('@/components/multiUse/SocialShare.vue'),
		WwImage,
		WwLogo: () => import('@/components/multiUse/WwLogo.vue'),
		WwAdvertisement: () => import('@/components/UI/WwAdvertisement.vue'),
		GleamEmbed: () => import('@/components/marketing/GleamEmbed.vue')
	},
	data () {
		return {
			isMounted: false,
			imageWidth: 730,
			imageHeight: 398,
			lazyWidth: 7,
			lazyHeight: 4,
			contentImageSizes: {
				width: 750,
				height: 750,
				lazyWidth: 8,
				lazyHeight: 8
			},
			observer: false,
			useFallbackImage: false,
			relatedArticles: [],
			DEFAULT_WW_GRAY_LOGO,
			BUSINESSES_LIST,
			BLOGS_LIST,
			BLOG_IMAGES
		}
	},
	apollo: {
		article: {
			query: GetBlogArticlePageData,
			update(data) {
				const metaData = { ...data.meta }
				if (data.meta) { this.$store.dispatch('setMeta', metaData) }
				if (data.auth) {
					this.$store.commit('auth/setAuth', { auth: data.auth })
				}
				if (data.blogArticles?.[0]) {
					return {
						...data.blogArticles[0]
					}
				}
				this.$store.commit('setError', { status: 404 })
				return []
			},
			variables() {
				return {
					seoUrl: this.$route.params.article
				}
			},
			error(error) {
				pageLevelGQLErrors(error, this.$store)
			},
			errorPolicy: 'none'
		},
		categories: {
			query: GetBlogCategoriesList,
			update(data) {
				if (data.blogCategories?.length) {
					return data.blogCategories
				}
				return []
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		},
		nearbyBusinesses: {
			query: GetNearbyBusinesses,
			update(data) {
				if (data?.cities?.[0]?.listings) {
					return {
						url: data.cities[0]?.url,
						listings: data.cities[0].listings
					}
				}
			},
			variables() {
				return {
					limit: 10
				}
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	},
	computed: {
		...mapGetters([ 'meta' ]),
		gleamEmbedHtml() {
			return this?.article?.contestURL
		},
		hasGleamEmbed() {
			return this.article?.contestURL?.length > 0
		},
		categoryUrl() {
			return this.article.category ? `/blog/${this.article.category[0].url}` : ''
		},
		articleCategoryUrl() {
			return this.article.category ? this.article.category[0].url : ''
		},
		articleContent() {
			const {
				width, height, lazyWidth, lazyHeight
			} = this.contentImageSizes
			if (!this.article?.content) return ''
			return this.article.content &&
				this.article.content.replace(/\/blog_images\/full\//g, `${CONTENT_SERVER}/blog_images/${lazyWidth}x${lazyHeight}/`)
					.replace(/<img/g, `<img width=${width} height=${height} class="lazy-load" aria-label="article-image"`)
		},
		browserSupportsIO() {
			return window && 'IntersectionObserver' in window
		},
		hasAuthorInfo() {
			return !!this.article.author
		},
		articleImage() {
			return this?.article?.image?.fileName
		},
		cityUrl() {
			return this.nearbyBusinesses?.url || ''
		},
		itemCount() {
			return this.nearbyBusinesses?.listings?.length
		},
		businesses() {
			return this.nearbyBusinesses?.listings
		},
		subHeading() {
			return 'Stay up to date with the cannabis industry'
		},
		hasRelatedArticles() {
			return this.relatedArticles?.length
		},
		authorName() {
			return this.article?.author?.name ? this.article.author.name : "Where's Weed"
		},
		beginningOfFirstParagraph() {
			return this.articleContent.indexOf('<p')
		},
		endOfFirstParagraph() {
			return this.articleContent.indexOf('/p>') + 3
		},
		firstParagraph() {
			return this.articleContent.slice(this.beginningOfFirstParagraph, this.endOfFirstParagraph)
		},
		articleContentSansFirstParagraph() {
			return this.articleContent.replace(this.firstParagraph, '')
		},
		articleDate() {
			return formatUnixDate(this.article?.date)
		}
	},
	watch: {
		async article() {
			this.relatedArticles = await this.getRelatedArticles()
		},
		articleContent() {
			if (this.observer) {
				this.$nextTick(() => {
					this.bindLazyLoad()
				})
			}
		}
	},
	async mounted() {
		this.isMounted = true
		this.bindLazyLoad()
	},
	methods: {
		buildArticleCard,
		async getRelatedArticles() {
			const { data } = await apolloClient.query({
				query: GetRelatedArticles,
				variables: {
					category: this.articleCategoryUrl,
					limit: 10
				}
			})
			return data?.blogArticles?.filter(article => article.id !== this.article.id) || []
		},
		bindLazyLoad() {
			try {
				if (this.browserSupportsIO) {
					if (!this.observer) {
						this.observer = new IntersectionObserver(this.lazyLoad)
					}
					const lazyLoadItems = [ ...document.getElementsByClassName('lazy-load') ]
					lazyLoadItems.forEach(lazyItem => this.observer.observe(lazyItem))
				}
			} catch (e) {
				logError(e)
			}
		},
		lazyLoad(entries, observer) {
			entries.forEach(entry => {
				try {
					if (entry.isIntersecting) {
						const {
							width, height, lazyWidth, lazyHeight
						} = this.contentImageSizes
						entry.target.src = entry.target.src.replace(`${lazyWidth}x${lazyHeight}`, `${width}x${height}`)
						observer.unobserve(entry.target)
						return
					}
				} catch (e) {
					logError(e)
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>

.blog-content {
	img {
		max-width: 100%;
		height: unset;
	}
	text-align: left;
	h1 {
		font-weight: 700;
		font-size: 2em;
		margin-bottom: 10px;
	}
	h2 {
		font-size: 1.75em;
	}
	h3 {
		font-size: 1.5em;
	}
	h4 {
		font-size: 1.25em;
		color: $primary2;
	}
	.breadcrumb {
		display: flex;
		background: white;
		li {
			font-size: 0.9em;
			padding: 0;
		}
		a {
			color: $primary2;
		}
		.active {
			a {
				color: $primary1;
				font-weight: 600;
				padding-left: 0.25rem;
			}
		}
		.breadcrumb-item + .breadcrumb-item::before {
			content: ">";
			padding-left: 0.5rem;
		}
	}
}
:deep(figure) {
	@apply md:w-1/2 md:pl-4 md:float-right md:my-2
}
:deep(h2) {
	clear: both;
}
:deep(figure + h3) {
	clear: none;
}
:deep(h3) {
	clear: both;
}
.related-articles {
	h3 {
		font-weight: 600;
		font-size: 1.2em !important;
		margin-bottom: 10px;
	}
}
</style>
