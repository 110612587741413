<template>
	<div class="max-w-6xl p-4 mx-auto text-left">
		<AuthorCard
			v-if="author"
			:author="author"
			:is-author-page="true"
		/>
		<p
			v-if="author && author.biography"
			class="px-4 mb-8"
		>
			{{ author.biography }}
		</p>
		<div
			v-if="author && author.blogArticles"
			class="grid grid-cols-1 space-x-4 md:grid-cols-2 lg:grid-cols-4"
		>
			<BlogCard
				v-for="(article, index) in mappedArticles"
				:key="index"
				:article="article"
			/>
		</div>
	</div>
</template>

<script async>
import AuthorCard from '@/components/blog/AuthorCard.vue'
import { GetBlogAuthorPageData } from '@/gql/blog/queries/pages.gql'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		AuthorCard,
		BlogCard: () => import('@/components/blog/Card.vue')
	},
	apollo: {
		author: {
			query: GetBlogAuthorPageData,
			update(data) {
				if (data.auth) {
					this.$store.commit('auth/setAuth', { auth: data.auth })
				}
				if (data.blogAuthors) {
					const meta = {
						metaInfo: {
							title: `${data.blogAuthors?.name} Author - Where's Weed`,
							description: data.blogAuthors?.biography
						}
					}
					this.$store.dispatch('setMeta', meta)

					return {
						...data.blogAuthors
					}
				}
				this.$store.commit('setError', { status: 404 })
				return {}
			},
			variables () {
				return {
					seoUrl: this.$route.params.authorName
				}
			},
			error(error) {
				error.graphQLErrors.forEach((errorItem) => {
					logError(errorItem)
				})
			},
			errorPolicy: 'all'
		}
	},
	computed: {
		mappedArticles() {
			return this.author.blogArticles.map(({ image, title, categories, datePublished, url }) => ({
				image: image?.fileName,
				title,
				category: categories[0].name,
				date: datePublished,
				url
			}))
		}
	}
}
</script>
