import buildImageUrl from '@/utils/builders/buildImageUrl.js'

export default article => {
	if (article.seo_url) {
		return {
			url: article.url,
			image: buildImageUrl({ file: article.image }),
			category: article.category_name,
			title: article.title,
			date: article.date
		}
	}
	return {
		...article,
		category: article?.category[0]?.name,
		image: article?.image?.fileName
	}
}
