<template>
	<div
		class="p-4 cursor-pointer"
		@click="$router.push(`/authors/${authorUrl}`)"
	>
		<AuthorInfo
			:author="author"
			bio-text-color="text-gray-500"
			:is-author-page="isAuthorPage"
		/>
	</div>
</template>

<script defer>
import AuthorInfo from '@/components/multiUse/AuthorInfo.vue'

export default {
	components: {
		AuthorInfo
	},
	props: {
		author: {
			type: Object,
			required: true
		},
		isAuthorPage: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		authorUrl() {
			return this.author.name.toLowerCase().replaceAll(' ', '-')
		}
	}
}
</script>
