<template>
	<div
		v-if="hasArticles"
		class="w-full max-w-6xl py-2 mx-auto"
	>
		<h1 class="px-4 mb-2 text-2xl font-bold text-left">
			{{ heading }}
		</h1>
		<div class="flex flex-wrap px-4 pb-4 lg:flex-nowrap">
			<router-link
				v-if="featuredArticle"
				class="relative block w-full m-2 overflow-hidden lg:flex-1 lg:m-0 aspect-[7/5]"
				:to="featuredArticle.url"
				:width="798"
				:height="570"
			>
				<WwImage
					:src="featuredArticle.image.fileName"
					object-cover
					:image-path="BLOG_IMAGES"
					classes="rounded-xl"
					:alt="featuredArticle.title"
					:width="798"
					:height="570"
					:lazy="false"
					fetch-priority="high"
				/>
				<h3
					class="absolute bottom-0 z-20 m-4 text-2xl leading-6 text-left text-white"
					v-html="featuredArticle.title"
				/>
				<div class="black-gradient-to-b" />
			</router-link>
			<SecondaryArticles
				v-if="secondaryArticles"
				:articles="secondaryArticles"
			/>
		</div>

		<div class="flex flex-wrap px-4 lg:flex-nowrap">
			<div class="w-full lg:w-2/3">
				<div
					v-if="otherArticles"
					class="mx-2 lg:mx-0 grid grid-cols-1 lg:gap-3 lg:grid-cols-2 aspect-[7/5]"
				>
					<BlogCard
						v-for="(article, index) in otherArticles"
						:key="index"
						:article="buildArticleCard(article)"
						class="relative my-2 text-left"
					/>
				</div>
				<WwPagination
					:current-page="Number(currentPage)"
					:page-count="pageCount"
					@page-change="changePage"
				/>
			</div>
			<BlogSidebar class="flex-1" />
		</div>
	</div>
</template>

<script async>
import WwPagination from '@/components/UI/WwPagination.vue'
import { BLOG_IMAGES } from '@/constants/images/image-folder-paths.js'
import { GetBlogCategoriesList } from '@/gql/blog/queries/components.gql'
import { GetBlogIndexPageData } from '@/gql/blog/queries/pages.gql'
import buildArticleCard from '@/utils/builders/buildArticleCard.js'
import { componentLevelGQLErrors, pageLevelGQLErrors } from '@/utils/error-handling.js'

export default {
	components: {
		WwImage: () => import('@/components/multiUse/WwImage.vue'),
		BlogCard: () => import('@/components/blog/Card.vue'),
		BlogSidebar: () => import('@/components/blog/Sidebar.vue'),
		SecondaryArticles: () => import('@/components/blog/SecondaryArticles.vue'),
		WwPagination
	},
	data() {
		return {
			slide: 0,
			sliding: null,
			currentPage: 1,
			perPage: 11,
			useFallbackImage: false,
			BLOG_IMAGES
		}
	},
	apollo: {
		page: {
			query: GetBlogIndexPageData,
			update(data) {
				if (data.meta) { this.$store.dispatch('setMeta', data.meta) }
				if (data.auth) {
					this.$store.commit('auth/setAuth', { auth: data.auth })
				}
				if (data.blogArticles?.length) {
					return {
						articles: data.blogArticles,
						meta: data.meta,
						paging: data.paging
					}
				}
				this.$store.commit('setError', { status: 404 })
				return []
			},
			variables() {
				return {
					pageName: !this.category ? 'blog' : 'blogCategory',
					seoUrl: this.category,
					limit: this.perPage,
					offset: this.blogOffset // #NOTE: Set this to `0` if we want to append the results for non-paginated list (infinite scroll)
				}
			},
			error(error) {
				pageLevelGQLErrors(error, this.$store)
			},
			errorPolicy: 'none'
		},
		categories: {
			query: GetBlogCategoriesList,
			update(data) {
				if (data.blogCategories?.length) {
					return data.blogCategories
				}
				return []
			},
			error(error) {
				componentLevelGQLErrors(error)
			}
		}
	},
	computed: {
		blogOffset() {
			return (this.currentPage - 1) * this.perPage || 0
		},
		hasArticles() {
			return this.page?.articles?.length
		},
		meta() {
			return this.page?.meta
		},
		heading() {
			return this.meta?.h1?.length ? this.meta.h1 : 'Cannabis News & Culture'
		},
		featuredArticle() {
			return this.page?.articles?.[0]
		},
		secondaryArticles() {
			return this.hasArticles ? [ this.page.articles[1], this.page.articles[2] ] : []
		},
		otherArticles() {
			return this.page?.articles?.slice(3) || []
		},
		articleCount() {
			return this.page?.paging?.blogArticles?.resultsCount || 0
		},
		pageCount() {
			return Math.ceil(this.articleCount / this.perPage) || 1
		},
		category() {
			return this.$route.params?.category || ''
		}
	},
	watch: {
		currentPage() {
			if (this.currentPage === 1 || !this.currentPage) {
				this.currentPage = 1
				this.$router.push({
					name: this.$route.name,
					params: {
						page: null
					}
				})
			}
		},
		pageCount() {
			if (!Number(this.pageCount) || this.pageCount < this.currentPage) {
				this.currentPage = 1
				this.$router.push({
					name: this.$route.name,
					params: {
						page: null
					}
				})
			}
		},
		category() {
			this.currentPage = 1
		}
	},
	mounted() {
		this.currentPage = !this.$route.params.page > this.pageCount ? 1 : Number(this.$route.params.page)
	},
	methods: {
		buildArticleCard,
		onSlideStart() {
			this.sliding = true
		},
		onSlideEnd() {
			this.sliding = false
		},
		changePage (changeAmount) {
			this.currentPage += changeAmount

			this.$router.push({
				name: this.$route.name,
				params: {
					page: this.currentPage
				}
			})
		}
	}
}
</script>

<style>
.black-gradient-to-b {
	@apply rounded-b-xl z-10 opacity-75 absolute left-0 bottom-0 w-full h-1/2;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
