<template>
	<div :class="authorPageAlignment">
		<UserAvatar
			:user="mappedForAvatar"
		/>
		<div class="flex flex-col ml-6">
			<div class="text-lg font-bold">
				{{ author.name }}
			</div>
			<div v-if="isAuthorPage">
				<div>
					Author | {{ articleCount }}
				</div>
				<SocialMediaLinks
					v-if="author.socialMedia"
					class="my-2"
					:links="author.socialMedia"
				/>
			</div>
			<div v-else>
				<div class="text-sm font-bold text-green-500 uppercase">
					View Author
				</div>
				<div
					v-if="false"
					class="text-sm"
				>
					{{ articleCount }}
				</div>
			</div>
		</div>
	</div>
</template>

<script async>
import SocialMediaLinks from '@/components/multiUse/SocialMediaLinks.vue'
import UserAvatar from '@/components/user/UserAvatar.vue'

export default {
	components: {
		UserAvatar,
		SocialMediaLinks
	},
	props: {
		author: {
			type: Object,
			required: true
		},
		bioTextColor: {
			type: String,
			default: 'text-black'
		},
		isAuthorPage: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		mappedForAvatar() {
			return { image: this?.author?.filename || this?.author?.image?.filename }
		},
		articleCount() {
			if (this?.author?.blogArticles) {
				return this?.author?.blogArticles?.length === 1 ? `${this?.author?.blogArticles?.length} article` : `${this?.author?.blogArticles?.length} articles`
			} else {
				return '0 articles'
			}
		},
		authorPageAlignment() {
			return this.isAuthorPage ? 'flex' : 'flex items-center'
		}
	}
}
</script>
