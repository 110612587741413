<template>
	<div class="flex">
		<a
			v-for="(value, key) in links"
			:key="key"
			class="text-black"
			:href="value"
			target="_blank"
			:aria-label="key"
		>
			<FacebookIcon
				v-if="key === 'facebook' && value.length"
				class="w-5 h-5 mr-5"
				aria-label="Facebook"
			/>
			<TwitterIcon
				v-if="key === 'twitter' && value.length"
				class="w-5 h-5 mr-5"
				aria-label="Twitter"
			/>
			<InstagramIcon
				v-if="key === 'instagram' && value.length"
				class="w-5 h-5 mr-5"
				aria-label="Instagram"
			/>
			<LinkedInIcon
				v-if="key === 'linkedin' && value.length"
				class="w-5 h-5 mr-5"
				aria-label="LinkedIn"
			/>
		</a>
	</div>
</template>

<script defer>
import FacebookIcon from '@/components/icons/FacebookIcon.vue'
import InstagramIcon from '@/components/icons/InstagramIcon.vue'
import LinkedInIcon from '@/components/icons/LinkedInIcon.vue'
import TwitterIcon from '@/components/icons/TwitterIcon.vue'

export default {
	components: {
		FacebookIcon,
		TwitterIcon,
		InstagramIcon,
		LinkedInIcon
	},
	props: {
		links: {
			type: Object,
			required: true
		}
	}
}
</script>
