<template>
	<DefaultLayout>
		<div>
			<CategoryList
				v-if="showCategories"
				:categories="categories"
				class="max-w-6xl mx-auto xl:px-10"
			/>
			<router-view
				id="blogShell"
				class="w-full"
			/>
		</div>
	</DefaultLayout>
</template>

<script async>
import CategoryList from '@/components/blog/CategoryList.vue'
import { GetBlogCategoriesList } from '@/gql/blog/queries/components.gql'
import DefaultLayout from '@/layouts/default/Index.vue'
import { gqlError } from '@/utils/error-handling.js'

export default {
	components: {
		DefaultLayout,
		CategoryList
	},
	apollo: {
		categories: {
			query: GetBlogCategoriesList,
			update(data) {
				if (data.blogCategories?.length) {
					return data.blogCategories
				}
				return []
			},
			error(error) {
				gqlError(error)
			},
			skip() {
				return !this.showCategories
			}
		}
	},
	computed: {
		showCategories () {
			return this.$route.name !== 'blogAuthorPage'
		}
	}
}
</script>
