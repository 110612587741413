<template>
	<div class="pt-2 min-h-14 xl:pt-4">
		<WwCarousel
			id="categoriesCarousel"
			ref="categoriesCarousel"
			:item-count="2"
			:items-per-slide="1"
			item-spacing="space-x-3 xl:space-x-4"
			side-scroller-class="px-4 pb-2 xl:mx-3 xl:pb-3"
			scrollbar-visibility="hide-horizontal-scrollbar"
			arrow-visibility="hidden xl:block"
			fade-visibility="hidden xl:block"
			:gradient-width="75"
			arrow-offset-classes="mb-3"
			right-arrow-position="-right-11"
			left-arrow-position="-left-2"
			arrow-size-classes="w-8 h-8"
			hide-footer
			hide-header
			:disable-autoscroll="isMobile"
			:initial-slide="initialSlide"
			:scroll-behavior="scrollBehavior"
			:current-item-index="itemIndex"
		>
			<template #slides>
				<router-link
					v-for="(category, index) in categories"
					:id="category.url"
					:ref="CAROUSEL_ITEM"
					:key="index"
					:to="{ name: 'blogCategory', params: { category: category.url, page: null } }"
					:class="[
						'flex px-5 py-2 hover:shadow text-sm font-semibold bg-white border-gray-300 rounded-full shadow-sm whitespace-nowrap xl:text-base',
						category.url === $route.params.category ? 'text-green-500 border-green-500 border-2		': 'text-black border'
					]"
				>
					{{ category.name }}
				</router-link>
			</template>
		</WwCarousel>
	</div>
</template>

<script async>
import { mapGetters } from 'vuex'

import WwCarousel from '@/components/UI/WwCarousel.vue'
import CAROUSEL_ITEM from '@/constants/carousel/carousel-item.js'
import SIDE_SCROLLER from '@/constants/UI/side-scroller.js'

export default {
	components: {
		WwCarousel
	},
	props: {
		categories: {
			type: Array,
			default: () => []
		},
		scrollBehavior: {
			type: String,
			default: 'smooth',
			validator: (value) => {
				return [ 'smooth', 'auto' ].includes(value)
			}
		}
	},
	data() {
		return {
			CAROUSEL_ITEM,
			isMounted: false,
			containerWidth: null,
			scrollWidth: null,
			itemWidth: null,
			currentItemOffsetLeft: null,
			itemPosition: null
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		currentCategory() {
			return this.$route.params.category
		},
		itemIndex() {
			return this.categories.findIndex(category => category.url === this.currentCategory)
		},
		initialSlide() {
			return this.itemPosition > this.containerWidth ? 2 : 1
		}
	},
	watch: {
		itemPosition: { // scroll on mounted
			handler(newVal, oldVal) {
				if (newVal !== oldVal && this.isMounted) {
					this.scrollToCurrentCategory()
				}
			},
			immediate: true
		},
		itemIndex: { // scroll on category change
			handler() {
				this.scrollToCurrentCategory()
			},
			immediate: true
		}
	},
	mounted() {
		this.$nextTick(function () {
			// Code that will run only after the
			// entire view has been rendered
			this.isMounted = true

			const interval = setInterval(() => { // hack to wait for carousel to mount
				if (this.$refs.carouselItem) {
					this.getMeasurements()
					clearInterval(interval)
				}
			}, 50)
		})
	},
	methods: {
		getMeasurements() {
			this.getContainerWidth()
			this.getScrollWidth()
			this.getCurrentItemOffsetLeft()
			this.getItemWidth()
			this.getItemPosition()
		},
		getItemWidth() {
			this.itemWidth = this.$refs?.carouselItem?.[this.itemIndex]?.$el?.offsetWidth
		},
		getItemPosition() {
			this.itemPosition = this.currentItemOffsetLeft + this.itemWidth
		},
		getScrollWidth() {
			this.scrollWidth = this.$refs?.carouselItem?.[0]?.$parent?.$refs?.[SIDE_SCROLLER]?.scrollWidth
		},
		getContainerWidth() {
			this.containerWidth = this.$refs?.carouselItem?.[0]?.$parent?.$refs?.[SIDE_SCROLLER].offsetWidth
		},
		getCurrentItemOffsetLeft() {
			this.currentItemOffsetLeft = this.$refs?.carouselItem?.[this.itemIndex]?.$el?.offsetLeft
		},
		scrollToCurrentCategory() {
			this.getMeasurements()
			this.handleScroll()
		},
		handleScroll() {
			if (this.isMobile) {
				let scrollLeft = 0
				const containerCenter = this.containerWidth / 2
				const itemCenter = this.itemWidth / 2
				if (this.itemIndex === 0) {
					scrollLeft = 0
				} else if (this.itemIndex === this.categories.length - 1) {
					scrollLeft = this.scrollWidth
				} else {
					scrollLeft = this.currentItemOffsetLeft - containerCenter + itemCenter
				}
				this.$refs?.carouselItem?.[0]?.$parent?.$refs?.[SIDE_SCROLLER]?.scrollTo({
					top: 0,
					left: scrollLeft,
					behavior: 'smooth'
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.router-link-exact-active {
	@apply text-green-500 border-green-500;
}
</style>
